:root {
  --uiPrimaryColor: #ffffff;
  --uiSecondaryColor: #aaa;
  --uiBackgroundColor: black;
  --uiFont: -apple-system;
}

body {
  margin: 0;
  font-family: var(--uiFont), BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--uiBackgroundColor);
  color: var(--uiPrimaryColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header {
  display: flex;
}

.crumb {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
