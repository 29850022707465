.panel {
  width: 300px;
  border-left: 1px solid var(--uiSecondaryColor);
  padding: 1rem;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--uiBackgroundColor);
  overflow-y: auto;
}

.panel > h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--uiSecondaryColor);
}

.panel dt {
  font-size: 1.2rem;
  color: var(--uiBackgroundColor);
  background-color: var(--uiPrimaryColor);
  padding: 0.25rem;
  margin-bottom: 0.5rem;
}

.panel input,
.panel select {
  width: 100px;
}

.panel label {
  max-width: 160px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
}
.panel dd {
  margin-left: 0;
  padding: 0.5rem 0;
}
.panel .item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.panel .description {
  color: var(--uiSecondaryColor);
}

.panel .keyboardShortcut--button {
  background-color: var(--uiSecondaryColor);
}
.panel .keyboardShortcut--set-area {
  background-color: var(--uiPrimaryColor);
  background-color: var(--uiBackgroundColor);
}
