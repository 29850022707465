.itemContainer {
  border-radius: 6px;
  display: flex;
}
.itemContainer.hovered {
  background-color: rgba(255, 255, 255, 0.1);
}

.contentContainer {
  display: flex;
  padding-top: 8px;
}

.expandToggle {
  width: 16px;
  height: 16px;
}
.expandToggle .materialIcons {
  visibility: hidden;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}
.expandToggle:hover .materialIcons.hasChildren {
  visibility: initial;
}

.bullet {
  margin-right: 4px;
  height: 16px;
  width: 16px;
}
.bullet:hover svg {
  transform: scale(1.3);
}

.content {
  flex: 1;
  cursor: text;
  min-height: 1rem;
}
.content:focus {
  outline: none;
}
.content.complete {
  text-decoration: line-through;
}

.expanded {
  font-weight: 300;
  font-size: 0.85rem;
  color: var(--uiSecondaryColor);
  margin-left: 24px;
  margin-top: 4px;
  border-left: 1px solid #aaa;
  padding-left: 10px;
}

.bumper {
  width: var(--bulletimeCoreBulletLevelPadding);
  content: "&nbsp;";
  border-right: var(--bulletimeCoreBulletBorderWidth) solid
    var(--bulletimeCoreBulletBorderColor);
  flex-shrink: 0;
}

.content .link {
  color: var(--uiSecondaryColor);
  font-weight: 700;
}

.content .tag {
  color: var(--uiSecondaryColor);
  font-weight: 700;
}

.pre {
  display: inline-block;
  padding: 1px 4px;
  margin: 0;
}

.content .pre {
  display: inline-block;
  border: 1px solid var(--uiSecondaryColor);
  padding: 1px 4px;
  margin: 0;
}

.underline {
  text-decoration: underline;
}

.expanded .link {
  color: var(--uiPrimaryColor);
  font-weight: 700;
}

.expanded .tag {
  color: var(--uiPrimaryColor);
  font-weight: 700;
}

.expanded .pre {
  background-color: var(--uiSecondaryColor);
  color: var(--uiBackgroundColor);
}

@media (max-width: 480px) {
  .expandToggle .materialIcons.hasChildren {
    visibility: initial;
  }
}
