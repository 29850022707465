[contenteditable]:focus {
  outline: none;
}

.App {
  display: flex;
  grid-area: main;
}

.header {
  display: flex;
  align-items: center;
  color: var(--uiPrimaryColor);
  padding: 16px;
  position: fixed;
  grid-area: header;
}
.header > i.material-icons {
  transition: 500ms ease-in-out both;
}

.header-top .header > i.material-icons {
  margin-right: 16px;
}

.header-top .header {
  top: 0;
  left: 0;
  right: 0;
  flex-direction: row;
  border-bottom: 1px solid var(--uiPrimaryColor);
}

.header-right .header {
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  border-right: 1px solid var(--uiPrimaryColor);
  width: 48px;
  padding: 16px 8px;
  background-color: var(--uiBackgroundColor);
  box-sizing: border-box;
}

.header-right .header > i.material-icons {
  margin: inherit;
  margin-top: 16px;
}

.App > :first-child {
  flex-grow: 1;
}

.header > .material-icons:hover {
  transition: 500ms ease-in-out both;
  transform: scale(1.4);
}

.material-icons {
  cursor: pointer;
}

.modal {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.App-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: grid;
  overflow-y: scroll;
}

.App-container.header-right {
  grid-template-columns: 48px 1fr;
  grid-template-rows: 100%;
  grid-template-areas: "header main";
}

.App-container.header-top {
  grid-template-columns: 100%;
  grid-template-rows: 48px 1fr;
  grid-template-areas: "header" "main";
}

.sync-status-container {
  flex-grow: 1;
  text-align: right;
  display: flex;
  flex-direction: column-reverse;
}
