.dialog {
  background-color: var(--uiBackgroundColor);
  width: 60vw;
  max-width: 600px;
  margin: 0 auto;
  border-width: 1px;
  border-style: solid;
  border-color: var(--uiPrimaryColor);
  border-top: none;
}

.header {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid var(--uiSecondaryColor);
  padding: 16px;
}

ul {
  list-style: none;
  padding: 0 16px;
}

li {
  padding: 4px 0px;
  cursor: pointer;
}

li:hover {
  background-color: var(--uiSecondaryColor);
}

li.current {
  background-color: var(--uiSecondaryColor);
}
